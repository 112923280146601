import * as React from "react"
import * as Yup from "yup"
import { useFormik } from "formik"
import { navigate } from "gatsby"
import {
  useToast,
  Flex,
  SimpleGrid,
  FormControl,
  Input,
  FormLabel,
  Button,
  Checkbox,
  Box,
  Text,
} from "@chakra-ui/react"

import { firebase } from "../../lib/firebase/client"
import { downloads } from "./data/downloads"
import langString from "../../locales/lang.json"

const validationSchema = Yup.object().shape({
  family_name: Yup.string().required("This field is required"),
  given_name: Yup.string().required("This field is required"),
  email: Yup.string()
    .email("Email address is not valid")
    .required("This field is required"),
})

export const DownloadsForm = ({ defaultValues, locale = "en" }) => {
  const toast = useToast()
  const [loading, setLoading] = React.useState(false)
  const [selectedDownloads, setSelectedDownloads] = React.useState([])

  const handleSubmit = values => {
    const db = firebase.firestore()

    setLoading(true)

    db.collection("literature_downloads")
      .add({
        ...values,
        selected_downloads: selectedDownloads,
        createdAt: firebase.firestore.Timestamp.now(),
      })
      .then(docRef => {
        toast({
          title: "Success!",
          description: "Your details were submitted successfully.",
          status: "success",
          duration: 9000,
          isClosable: true,
        })
        navigate("/downloads")
      })
      .catch(err => {
        console.error("Problem submitting the literature form:", err)
        toast({
          title: "Oops there was a problem.",
          description:
            "Sorry we were unable to submit your details. Please check the information and try again.",
          status: "error",
          duration: 9000,
          isClosable: true,
        })
        setLoading(false)
      })
  }

  const formik = useFormik({
    initialValues: {
      family_name: "",
      given_name: "",
      email: "",
    },
    onSubmit: values => handleSubmit(values),
    validationSchema,
  })

  const handleCheckboxChange = e => {
    // Get the name
    const { name, checked } = e.target

    // Check if the selected downloads array contains this value
    const itemsToFind = selectedDownloads.map(item => item.value)

    // If the item is in the array and the result of the check is false
    // remove the item from the array. Otherwise update array
    if (itemsToFind.includes(name)) {
      if (!checked) {
        const newState = selectedDownloads.filter(item => item.value !== name)
        setSelectedDownloads(newState)
      }
    } else {
      const item = downloads.find(item => item.value === name)
      setSelectedDownloads([...selectedDownloads, item])
    }
  }

  return (
    <form onSubmit={formik.handleSubmit}>
      <SimpleGrid columnGap={4} columns={{ base: 1, md: 2 }}>
        <FormControl mb={4}>
          <FormLabel htmlFor="given_name">
            {langString["contact-page"]["first-name"][locale]}
          </FormLabel>
          <Input
            type="text"
            onChange={formik.handleChange}
            id="given_name"
            name="given_name"
            value={formik.values.given_name}
          />
          <Text color="#E53E3E">{formik.errors.given_name}</Text>
        </FormControl>

        <FormControl>
          <FormLabel htmlFor="family_name">
            {langString["contact-page"]["last-name"][locale]}
          </FormLabel>
          <Input
            type="text"
            onChange={formik.handleChange}
            id="family_name"
            name="family_name"
            value={formik.values.family_name}
          />
          <Text color="#E53E3E">{formik.errors.family_name}</Text>
        </FormControl>
      </SimpleGrid>

      <FormControl mb={12}>
        <FormLabel htmlFor="email">
          {langString["contact-page"]["email-address"][locale]}
        </FormLabel>
        <Input
          type="text"
          onChange={formik.handleChange}
          id="email"
          name="email"
          value={formik.values.email}
        />
        <Text color="#E53E3E">{formik.errors.email}</Text>
      </FormControl>

      <SimpleGrid columnGap={4} columns={{ base: 1, md: 2 }} mb={8}>
        <Box>
          <Text mb={4}>
            {langString.productLiterature["download-statement"][locale]}:
          </Text>
          {downloads.map((download, index) => {
            return (
              <FormControl key={index}>
                <FormLabel htmlFor={download.value}>
                  <Checkbox
                    name={download.value}
                    id={download.value}
                    mr={2}
                    onChange={handleCheckboxChange}
                  />
                  {download.label}
                </FormLabel>
              </FormControl>
            )
          })}
        </Box>

        <Box>
          <Text>{langString.productLiterature.privacy[locale]}</Text>
        </Box>
      </SimpleGrid>

      <Flex justifyContent="center">
        <Button
          colorScheme="blue"
          type="submit"
          isLoading={loading}
          loadingText="Please wait"
          isDisabled={
            Object.keys(formik.errors).length > 0 ||
            selectedDownloads.length === 0
          }
        >
          {langString.productLiterature.button[locale]}
        </Button>
      </Flex>
    </form>
  )
}
