import * as React from "react"
import { Helmet } from "react-helmet"
import { StaticImage } from "gatsby-plugin-image"
import { Box, Flex, Text } from "@chakra-ui/react"
import { Container } from "../../components/Container"
import { Heading } from "../../components/Heading"
import { Layout } from "../../layouts/default"
import { DownloadsForm } from "../../components/Forms/DownloadsForm"
import langString from "../../locales/lang.json"

// Sanity helpers
import { FormulationChallengeBlock } from "../../components/Blocks/FormulationChallengeBlock"

const ProductLiterature = ({ pageContext }) => {
  const { locale } = pageContext

  return (
    <Layout>
      <Helmet>
        <meta name="title" content="Product literature sign up page" />
        <meta
          name="description"
          content="Enquiry details capture page for anyone wanting to download literature from Micropore Technologies"
        />
      </Helmet>

      <Box height="500px" position="relative" mb={-24} bg="blue.100">
        <StaticImage
          src="../../images/product-literature-header.jpg"
          alt=""
          style={{
            position: "absolute",
            width: "100%",
            height: "100%",
            top: 0,
            left: 0,
            zIndex: 1,
          }}
        />
      </Box>

      <Container px={0}>
        <Flex
          flexDir={{ base: "column", lg: "row" }}
          justifyContent={{ lg: "space-between" }}
        >
          <Box
            bg="white"
            width={{ lg: "50%" }}
            p={{ base: 4, md: 12 }}
            pb={0}
            pos="relative"
            zIndex={2}
          >
            <Heading fontSize="40px" mb={2}>
              {langString.productLiterature.title[locale]}
            </Heading>
          </Box>
        </Flex>

        <Container bg="white" mb={20}>
          <Text fontSize={18} mb={8}>
            {langString.productLiterature.intro[locale]}
          </Text>

          <DownloadsForm locale={locale} />
        </Container>

        <Container
          bg="white"
          pt={4}
          borderTopWidth={2}
          borderTopColor="gray.300"
        >
          <FormulationChallengeBlock locale={locale} />
        </Container>
      </Container>
    </Layout>
  )
}

export default ProductLiterature
