/**
 * Array of download objects. Used in the download form.
 */

export const downloads = [
  {
    label: "AXF equipment range brochure",
    value: "axf-equipment-range-brochure",
  },
  {
    label: "LDC-1 (lab scale) brochure",
    value: "ldc-1-lab-scale-brochure",
  },
  {
    label: "Pharmaceutical case study",
    value: "pharmaceutical-case-study",
  },
  {
    label: "Liposomes & Lipids white paper",
    value: "liposomes-and-lipids-white-paper",
  },
  {
    label: "PLGA white paper",
    value: "plga-white-paper",
  },
  {
    label: "API Crystallisation white paper",
    value: "api-crystallisation-white-paper",
  },
  {
    label: "Core-shell coacervation white paper",
    value: "core-shell-coacervation-white-paper",
  },
  {
    label: "Silica particles white paper",
    value: "Silica-particles-white-paper",
  },
  {
    label: "Hydrogels white paper",
    value: "hydrogels-white-paper",
  },
  {
    label: "Phase Change Materials white paper",
    value: "phase-change-materials-white-paper",
  },
  {
    label: "Pathfinder Brochure",
    value: "pathfinder-brochure",
  },
]
